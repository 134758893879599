import React, { FC, ReactNode } from 'react'

import { TextContent } from './styled'

type Props = {
  title?: string
  text?: string
  icon?: ReactNode
  iconPosition?: string
  titleComponent?: ReactNode
  textComponent?: ReactNode
}

const Infobox: FC<Props> = ({
  title,
  text,
  icon,
  iconPosition = 'top',
  titleComponent,
  textComponent,
}) => (
  <div>
    {iconPosition === 'top' && icon}
    {titleComponent ? titleComponent : (title && <h3>{title}</h3>)}
    {textComponent ? textComponent : (text && <TextContent>{text}</TextContent>)}
  </div>
)

export default Infobox
