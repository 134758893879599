import React, { useState } from 'react'
import { TabList, TabListItem, TabPanels, Table, Td, Th } from './styled'
import Item from './Item'

const Tabs = () => {
  const [openedTab, setOpenedTab] = useState(0)
  return (
    <div>
      <TabList role="tablist">
        <TabListItem
          role="tab"
          aria-selected={openedTab === 0}
          aria-controls="myth-panel"
          onClick={() => setOpenedTab(0)}
        >
          Myth
        </TabListItem>
        <TabListItem
          role="tab"
          aria-selected={openedTab === 1}
          aria-controls="fact-panel"
          onClick={() => setOpenedTab(1)}
        >
          Fact
        </TabListItem>
      </TabList>
      <TabPanels>
        <Table visibleColumn={openedTab}>
          <thead>
            <tr>
              <Th>Myth</Th>
              <Th>Fact</Th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <Td>
                <Item text="Fertility is only a women’s issue." />
              </Td>
              <Td>
                <Item
                  text="When it comes to fertility, it’s an equal playing field. In fact, of the 40–50% of couples who have trouble conceiving, “male factor” infertility is the cause."
                  positive
                />
              </Td>
            </tr>
            <tr>
              <Td>
                <Item text="If my sperm is “bad,” it’s all over." />
              </Td>
              <Td>
                <Item
                  text="It’s really just the start. Simple lifestyle changes like improving your diet or adding a few laps at the track are easy ways to begin improving your fertility."
                  positive
                />
              </Td>
            </tr>
            <tr>
              <Td>
                <Item text="You can have a healthy baby at any age or life stage, and only women need to worry about their age as it relates to childbearing." />
              </Td>
              <Td>
                <Item
                  text="Numbers don’t discriminate. While there are those random 90-something-year-olds getting women pregnant, researchers have linked “advanced paternal age” (defined as age 35+) with a plethora of health challenges."
                  positive
                />
              </Td>
            </tr>
            <tr>
              <Td>
                <Item text="So—should I switch to boxers?" />
              </Td>
              <Td>
                <Item
                  text="Briefs won’t impact your sperm health long-term, but if you’re actively trying to conceive you should make the switch."
                  positive
                />
              </Td>
            </tr>
          </tbody>
        </Table>
      </TabPanels>
    </div>
  )
}

export default Tabs
