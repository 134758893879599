import styled from 'styled-components'
import theme from 'styles/theme'
import {
  Center,
  Section,
  SectionDescription,
  ringThickness,
  ringSpacing,
} from '../styled'
import mq from 'styles/mq'
import { heading, paragraph } from 'styles/typography'
import Whale from 'components/icons/whale'
import { PrimaryLink } from 'components/links'

export const Wrap = styled(Section as any)`
  position: relative;
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  ${mq.medium} {
    padding: 0;
  }

  ${mq.large} {
    padding: 12rem 3rem !important;
  }
`

export const IconContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 30rem;
  margin: 0 auto;
  padding: 0 ${2 * ringThickness.small + ringSpacing.small}rem
    ${2 * ringThickness.small + ringSpacing.small}rem;

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: ${ringThickness.small}rem solid ${theme.color.accent.secondary};
    border-top: none;
    border-radius: 0 0 50vw 50vw;
  }

  &:after {
    margin: 0 ${ringThickness.small + ringSpacing.small}rem
      ${ringThickness.small + ringSpacing.small}rem;
  }

  ${mq.medium} {
    max-width: 70rem;
    padding: 0 ${2 * ringThickness.medium + ringSpacing.medium}rem
      ${2 * ringThickness.medium + ringSpacing.medium}rem;

    &:before,
    &:after {
      border-width: 0 ${ringThickness.medium}rem ${ringThickness.medium}rem;
    }

    &:after {
      margin: 0 ${ringThickness.medium + ringSpacing.medium}rem
        ${ringThickness.medium + ringSpacing.medium}rem;
    }
  }

  ${mq.large} {
    position: static;
    display: block;
    height: auto;
    padding: 0;

    &:before,
    &:after {
      border-width: 0 ${ringThickness.large}rem ${ringThickness.large}rem;
    }

    &:after {
      margin: 0 ${ringThickness.large + ringSpacing.large}rem
        ${ringThickness.large + ringSpacing.large}rem;
    }
  }
`

export const Content = styled(Center as any)`
  position: relative;
  text-align: center;
  color: ${theme.color.text.light};
  margin: 2.4rem auto 0;
  padding: 0 !important;
  z-index: 2;

  ${mq.medium} {
    margin-top: 8rem;
  }

  ${mq.large} {
    margin: 0 ${2 * ringThickness.large + ringSpacing.large}rem
      ${2 * ringThickness.large + ringSpacing.large}rem;
  }
`

export const Title = styled.h1`
  ${heading.xxxl};
  margin-bottom: 1.6rem;
  font-size: 4.8rem;
  text-transform: uppercase;

  ${mq.medium} {
    font-size: 10.4rem;
  }
`

export const Description = styled(SectionDescription as any)`
  font-size: 1.2rem;
  max-width: 24rem;

  ${mq.medium} {
    ${paragraph.normal};
    max-width: 51.9rem;
  }
`

export const StyledWhaleIcon = styled(Whale)`
  color: ${theme.color.accent.secondary};
  width: 8.1rem;
  height: auto;
  display: block;
  margin: 25% auto;

  ${mq.medium} {
    width: 12rem;
  }

  ${mq.large} {
    width: 6.4rem;
    margin: 0 auto 4rem;
  }
`

export const ButtonDown = styled(PrimaryLink)`
  text-align: center;
  display: block;
  margin-top: 3.2rem;

  svg {
    color: ${theme.color.accent.primary};
    display: block;
    transform: rotate(90deg);
    margin: 0.95rem auto 0;

    g {
      stroke: ${theme.color.accent.primary};
    }
  }
`
