import styled from 'styled-components'

import theme from 'styles/theme'
import mq from 'styles/mq'
import ShootingStar from 'components/icons/shooting-star'
import {
  Center,
  SectionDescription as SectionDescriptionBase,
  SectionTitle as SectionTitleBase,
} from '../styled'

export const StyledCenter = styled(Center as any)`
  max-width: 64rem;
`

export const ShootingStarIcon = styled(ShootingStar)`
  color: ${theme.color.accent.secondary};
  display: block;
  margin: 4.8rem auto 0 auto;
  width: 9.6rem;
  height: 9.6rem;

  ${mq.medium} {
    width: 20.4rem;
    height: 20.4rem;
    margin-top: 10rem;
  }
`

export const SectionTitle = styled(SectionTitleBase as any)`
  margin: 0 auto 2.4rem auto;

  ${mq.medium} {
    max-width: unset;
  }
`

export const SectionDescription = styled(SectionDescriptionBase as any)`
  padding: 0 3em;

  ${mq.medium} {
    padding: 0;
    max-width: 52.4rem;
  }
`
