import React from 'react'

import { SectionTitle, Section } from '../styled'
import {
  InfoboxTitle,
  InfoboxText,
  InfoboxIcon,
  InfoboxListItem,
  StyledCenter,
  CigaretteIcon,
  MarryJaneIcon,
  JunkIcon,
  SheepIcon,
} from './styled'
import Infobox from '../Infobox'

const YourHands = () => (
  <Section>
    <SectionTitle>It’s in your hands</SectionTitle>
    <StyledCenter>
      <ul>
        <InfoboxListItem>
          <Infobox
            titleComponent={<InfoboxTitle>Quit the Cigs</InfoboxTitle>}
            textComponent={
              <InfoboxText>
                Plenty of evidence links cigarette smoking to negative effects
                on several important factors to your semen, from count to
                motility and beyond. Long story short? Men and women who smoke
                have decreased fertility.
              </InfoboxText>
            }
            icon={
              <InfoboxIcon>
                <CigaretteIcon />
              </InfoboxIcon>
            }
          />
        </InfoboxListItem>
        <InfoboxListItem>
          <Infobox
            titleComponent={<InfoboxTitle>Skip the Mary Jane</InfoboxTitle>}
            textComponent={
              <InfoboxText>
                While there is limited evidence evaluating the effects of
                marijuana on fertility, brand new research just demonstrated a
                link between miscarriages and fathers’ marijuana use.
              </InfoboxText>
            }
            icon={
              <InfoboxIcon>
                <MarryJaneIcon />
              </InfoboxIcon>
            }
          />
        </InfoboxListItem>
        <InfoboxListItem>
          <Infobox
            titleComponent={<InfoboxTitle>Cut the Junk</InfoboxTitle>}
            textComponent={
              <InfoboxText>
                Junk food has its name for a reason. Many studies point to the
                fact that following a healthy diet is associated with better
                semen quality.
              </InfoboxText>
            }
            icon={
              <InfoboxIcon>
                <JunkIcon />
              </InfoboxIcon>
            }
          />
        </InfoboxListItem>
        <InfoboxListItem>
          <Infobox
            titleComponent={<InfoboxTitle>Sleep On It</InfoboxTitle>}
            textComponent={
              <InfoboxText>
                Get your eight hours! Late bedtimes and sleep deprivation impair
                your sperm health, weakening your fertility.
              </InfoboxText>
            }
            icon={
              <InfoboxIcon>
                <SheepIcon />
              </InfoboxIcon>
            }
          />
        </InfoboxListItem>
      </ul>
    </StyledCenter>
  </Section>
)

export default YourHands
