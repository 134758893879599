import styled from 'styled-components'
import theme from 'styles/theme'

export const IconContainer = styled.span<{ positive?: boolean }>`
  width: 1em;
  height: 1em;
  font-size: 3.3rem;
  border-radius: 1.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  color: ${theme.color.text.light};
  background: ${({ positive }) =>
    positive ? theme.color.accent.positive : '#FF5C1A'};
`
