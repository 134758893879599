import React, { FC, SVGProps } from 'react'
import Svg from 'components/svg'

const Junk: FC<SVGProps<SVGSVGElement>> = props => (
  <Svg {...props} viewbox="0 0 88 88">
    <g fill="currentColor" fillRule="nonzero">
      <path d="M27 64c3-1 6-1 8-4l2-5c0-2 0-2 2-2 3-1 7-3 8-7l1-3 3-1c4-1 7-5 7-9l2-1 4-2 5-5 1-4h2l5-5c0-4-4-3-7-2-4 1-7 4-8 8l-1 2-4 1-5 5-1 4-3 1-5 2-3 6v1l-1 1-3 1c-3 1-5 3-6 5l-1 4v1h-2c-5 1-8 5-8 9 0 2 0 1-2 2-2 0-4 2-5 4-1 5 5 4 8 2 5-1 6-5 7-9zm-8 8h-3c-3 0-2-2 0-3l4-1 1-4c1-4 4-5 7-6 1 0 3 0 4-2v-3c1-4 5-5 8-6 3 0 2-4 3-6 2-4 5-3 8-4s2-6 4-8l4-3 4-1 1-3c0-2 1-4 3-5 1-1 8-3 7 0-2 3-6 0-7 4v3c-1 3-3 4-5 5l-4 1-2 2c0 4-1 7-5 8h-4l-1 4c-1 2-1 4-3 5l-5 2c-1 0-3 0-3 2l-1 5c-1 2-3 3-5 3l-4 1-1 4c-1 3-2 5-5 6z" />
      <path d="M85 19c3-4 2-10-2-14S73 0 69 3c-7-5-17-4-23 2L5 46c-6 6-7 16-2 23-3 4-2 10 2 14s10 5 14 2c7 5 17 4 23-2l41-41c6-6 7-16 2-23zM9 50L50 9c4-3 10-4 14-1L8 64c-3-4-2-10 1-14zm7 29c-2 2-5 2-7 0s-2-5 0-7v-1L71 9h1c2-2 5-2 7 0s2 5 0 7v1L17 79h-1zm22 0c-4 3-10 4-14 1l56-56c3 4 2 10-1 14L38 79z" />
    </g>
  </Svg>
)

export default Junk
