import React from 'react'

import theme from 'styles/theme'
import Infobox from '../Infobox'
import { Center, SectionTitle } from '../styled'
import {
  Column,
  Content,
  InfoboxText,
  CounterText,
  CounterWrap,
  Circle,
  CounterHeading,
  Fill,
  Section,
} from './styled'

const HardFacts = () => (
  <Section>
    <SectionTitle>The hard facts</SectionTitle>
    <Center>
      <Content>
        <Column>
          <Infobox
            icon={
              <Circle
                value={0}
                id="average-age"
                thickness="thin"
                background={theme.color.background.gray}
              >
                <Fill>
                  <CounterHeading>Average age</CounterHeading>
                  40
                </Fill>
              </Circle>
            }
            textComponent={
              <InfoboxText>
                40 years ago, men had twice as much sperm as they do today.
              </InfoboxText>
            }
          />
        </Column>
        <Column>
          <Infobox
            icon={
              <CounterWrap>
                <Circle
                  value={50}
                  id="average-50"
                  thickness="thin"
                  background={theme.color.background.gray}
                >
                  <CounterText>50%</CounterText>
                </Circle>
              </CounterWrap>
            }
            textComponent={
              <InfoboxText>
                Roughly half of all fertility issues are related to
                complications with male fertility.
              </InfoboxText>
            }
          />
        </Column>
        <Column>
          <Infobox
            icon={
              <CounterWrap>
                <Circle
                  value={13}
                  id="average-12"
                  thickness="thin"
                  background={theme.color.background.gray}
                >
                  <CounterText>1 in 8</CounterText>
                </Circle>
              </CounterWrap>
            }
            textComponent={
              <InfoboxText>
                The number of U.S. couples who have fertility issues.
              </InfoboxText>
            }
          />
        </Column>
      </Content>
    </Center>
  </Section>
)

export default HardFacts
