import React, { FC, ChangeEvent } from 'react'

import {
  Title,
  Description,
  Bullet,
  Container,
  Content,
  Label,
  RadioInput,
} from './styled'

type Props = {
  item: { title: string; text: string }
  onChange: (evt: ChangeEvent<HTMLInputElement>) => void
  checked: boolean
}

const Item: FC<Props> = ({ item, checked, onChange }) => (
  <Container>
    <Label>
      <Title>{item.title}</Title>
      <Content>
        <RadioInput
          name="sample"
          value={item.title}
          checked={checked}
          onChange={onChange}
        />
        <Bullet />
        <Description dangerouslySetInnerHTML={{ __html: item.text }} />
      </Content>
    </Label>
  </Container>
)

export default Item
