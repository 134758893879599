import React, { FC, SVGProps } from 'react'
import Svg from 'components/svg'

const Sheep: FC<SVGProps<SVGSVGElement>> = props => (
  <Svg {...props} viewbox="0 0 104 76">
    <g fill="currentColor" fillRule="nonzero">
      <circle cx="19.5" cy="32.5" r="2.5" />
      <path d="M45 30l2-2-2-2c-3 0-5-2-5-5 0-1-1-2-3-2l-2 2c0 5 4 9 10 9zM56 25c6 0 10-4 10-9l-2-2c-2 0-3 1-3 2 0 3-2 5-5 5l-2 2 2 2zM78 43c-3 0-5-2-5-5l-1-2h-2c-4 3-8 3-11 0l-4 1 1 3c4 3 9 3 13 2 2 3 5 5 9 5l2-2-2-2zM50 47c-3 3-8 3-11 0l-4 1 1 3c2 2 5 3 9 3 3 0 6-1 8-3l1-3-4-1z" />
      <path d="M104 33c0-4-3-8-7-9a12 12 0 00-14-12 12 12 0 00-16-9 16 16 0 00-22 2 14 14 0 00-18 3 9 9 0 00-12 4 7 7 0 00-3 12l-7 5a11 11 0 007 20l10-1 2 5v2c0 4 3 8 7 9v6c0 3 2 6 6 6 3 0 6-3 6-6v-1l8-1a16 16 0 0023-2l4 1 2-1v4c0 3 3 6 6 6 4 0 6-3 6-6V57a12 12 0 0010-18l2-6zM23 43l-11 2h-1c-3 0-6-3-6-7 0-2 1-4 3-5l10-6h1c4 0 8 3 8 7 0 5-1 8-4 9zm14 27l-1 1-1-1v-5l2 2v3zm50-8v8l-1 1-2-1v-2l-1-3 4-3zm10-22l2 5c0 4-3 7-7 7l-4-1h-2l-1 3v1c0 3-3 7-7 7l-4-1h-3a12 12 0 01-18 2 2 2 0 00-2 0c-5 2-11 1-15-3l-2-1c-3 1-5-2-5-4v-2l-1-2-2-5c4-3 5-8 5-13 0-6-5-12-12-12h-2a2 2 0 010-4l3-2c0-2 2-3 4-3l3 1a2 2 0 003-1c2-3 5-5 8-5l6 3h2l2-1a12 12 0 0117-1h2l4-1c4 0 7 3 7 7v1l1 3h2l4-1a7 7 0 017 8l1 3h1a5 5 0 013 9 2 2 0 000 3z" />
    </g>
  </Svg>
)

export default Sheep
