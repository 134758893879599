import React from 'react'

import Container from 'components/container'
import { ButtonLink } from 'components/button'
import urls from 'common/urls'
import { Title, Wrap, StyledCenter, Rings } from './styled'

const Footer = () => {
  return (
    <Container>
      <Wrap>
        <StyledCenter>
          <Title>Ready to aim higher?</Title>
          <ButtonLink inverted to={urls.kit}>
            Get a kit
          </ButtonLink>
          <Rings />
        </StyledCenter>
      </Wrap>
    </Container>
  )
}

export default Footer
