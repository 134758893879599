import styled from 'styled-components'

import mq from 'styles/mq'
import { Center, SectionDescription } from '../styled'

export const StyledCenter = styled(Center as any)`
  padding: 0;

  ${mq.medium} {
    margin-left: auto;
    margin-right: auto;
  }
`

export const StyledDescription = styled(SectionDescription as any)`
  max-width: 34.424rem;
`

export const TextContent = styled.div<{ textRight?: boolean }>`
  display: flex;
  scroll-snap-type: x mandatory;
  scroll-padding: 0 2rem;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  &:before,
  &:after {
    display: block;
    content: '';
    width: 1rem;
    flex-shrink: 0;
  }

  &:after {
    widht: 2rem;
  }

  ${mq.medium} {
    margin-left: -5rem;
    margin-right: -5rem;
    margin-bottom: -2rem;
    padding-bottom: 2rem;

    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar,
    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }

    &:before,
    &:after {
      width: 4rem;
    }
  }

  ${mq.large} {
    display: grid;
    grid-template-columns: 30rem 30rem;
    grid-row-gap: 4rem;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;

    &:before,
    &:after {
      display: none;
    }
  }
`

export const ContentWrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 4.8rem;

  ${mq.medium} {
    margin-top: 10.5rem;
  }

  ${mq.large} {
    display: block;
  }
`

export const ImageWrap = styled.div`
  margin: 0 auto 6.5rem;
  width: 38.627rem;
  max-width: calc(100% - 7rem);
  order: -1;

  ${mq.large} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
  }
`
