import React, { FC, SVGProps } from 'react'
import Svg from 'components/svg'

const Cigarette: FC<SVGProps<SVGSVGElement>> = props => (
  <Svg {...props} viewbox="0 0 26 68">
    <path
      d="M24 51l-4-6 3-8V0H5v36l-5 9 4 7-4 7 6 9h20l-6-9 4-8zM11 6h7v13h-7V6zm6 45l-4 8 2 3h-5l-3-4 4-7-4-7 4-7V24h7v11l-5 10 4 6z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </Svg>
)

export default Cigarette
