import styled, { css } from 'styled-components'

import mq from 'styles/mq'
import { heading, paragraph } from 'styles/typography'
import CircleBase from 'components/circle'
import theme from 'styles/theme'
import { Section as SectionBase } from '../styled'

export const Section = styled(SectionBase as any)`
  padding-top: 8rem !important;

  ${mq.medium} {
    padding-top: 12rem !important;
  }
`

export const Content = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mq.desktop} {
    flex-direction: row;
    justify-content: space-between;
    align-items: unset;
  }
`

export const Column = styled.div`
  color: ${theme.color.text.light};
  text-align: center;
  margin-bottom: 6.4rem;

  ${mq.desktop} {
    width: 30%;
    margin-bottom: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`

export const CounterWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`

export const Circle = styled(CircleBase)`
  display: flex;
  justify-content: center;
  font-size: 27rem;
`

export const CounterText = styled.h3<{ dark?: boolean }>`
  ${heading.xxl};
  padding-top: 0.2em;
  margin: 0;

  ${({ dark }) =>
    dark &&
    css`
      color: ${theme.color.text.dark};
    `}
`

export const Fill = styled(CounterText as any)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: ${theme.color.text.dark};
  background-color: ${theme.color.background.gray};
`

export const CounterHeading = styled.span`
  display: block;
  margin-bottom: 1.1em;
  text-transform: uppercase;
  font-size: 1.2rem;
  letter-spacing: 0.1rem;
`

export const InfoboxText = styled.p`
  ${paragraph.small};
  margin: 4rem auto 0;
  max-width: 27.5rem;
`
