import React, { FC, SVGProps } from 'react'
import Svg from 'components/svg'

const Cross: FC<SVGProps<SVGSVGElement>> = props => (
  <Svg {...props} viewBox="0 0 12 12" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.95455 0.227273L0.227273 1.95455L4.31818 6.04545L0 10.3636L1.63636 12L5.95455 7.68182L10.0455 11.7727L11.7727 10.0455L7.68182 5.95455L12 1.63636L10.3636 0L6.04545 4.31818L1.95455 0.227273Z"
      fill="currentColor"
      stroke="white"
      strokeWidth={0.8}
    />
  </Svg>
)

export default Cross
