import React, { FC, SVGProps } from 'react'
import Svg from 'components/svg'

const Tick: FC<SVGProps<SVGSVGElement>> = props => (
  <Svg {...props} viewBox="0 0 17 13">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.4881 0.857117L5.61162 9.5085L2.35516 6.25479L1.14307 7.41305L5.59662 11.8628L15.7064 2.00904L14.4881 0.857117Z"
      fill="currentColor"
    />
  </Svg>
)

export default Tick
