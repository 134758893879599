import React, { useState, ChangeEvent } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

import { SectionTitle, Section } from '../styled'
import {
  StyledCenter,
  ContentWrap,
  ImageWrap,
  TextContent,
  StyledDescription,
} from './styled'
import Item from './item'

const data = [
  {
    title: 'Motility',
    text: 'How your sperm<br />are swimming',
  },
  {
    title: 'Volume',
    text: 'mL of your entire<br />sample',
  },
  {
    title: 'Morphology',
    text: 'How your sperm are shaped',
  },
  {
    title: 'Total Motile Count',
    text: 'The total number of swimming sperm in your sample',
  },
  {
    title: 'Count',
    text: 'How many millions of sperm are in your sample',
  },
  {
    title: 'Concentration',
    text: 'How many million<br />sperm per mL',
  },
]

const query = graphql`
  query {
    image: file(relativePath: { eq: "sperm-scope.png" }) {
      childImageSharp {
        fluid(quality: 70, maxWidth: 386) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const Sample = () => {
  const images = useStaticQuery(query)
  const [selectedValue, setSelectedValue] = useState(data[0].title)

  const handleRadioChange = (evt: ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(evt.target.value)
  }

  return (
    <Section>
      <SectionTitle noMarginBottom>The sample</SectionTitle>
      <StyledDescription>
        There’s more to sperm health than count. Meet the sperm health
        indicators:
      </StyledDescription>
      <StyledCenter>
        <ContentWrap>
          <TextContent>
            {data.map(item => (
              <Item
                item={item}
                key={item.title}
                checked={item.title === selectedValue}
                onChange={handleRadioChange}
              />
            ))}
          </TextContent>
          <ImageWrap>
            <Img fluid={images.image.childImageSharp.fluid} />
          </ImageWrap>
        </ContentWrap>
      </StyledCenter>
    </Section>
  )
}

export default Sample
