import styled from 'styled-components'
import mq from 'styles/mq'
import { heading } from 'styles/typography'
import { Section, Center, ringThickness, ringSpacing } from '../styled'
import theme from 'styles/theme'

export const Wrap = styled(Section as any)`
  position: relative;
  width: 100%;
  text-align: center;
  padding: 0;

  ${mq.medium} {
    padding: ${2 * ringThickness.medium + ringSpacing.medium}rem
      ${2 * ringThickness.medium + ringSpacing.medium}rem 0;
  }
`

export const Rings = styled.div`
  position: relative;
  margin-top: 4rem;
  padding-top: calc(80% - 6rem);
  width: 100%;

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: ${ringThickness.small}rem solid ${theme.color.accent.secondary};
    border-bottom: none;
    border-radius: 50vw 50vw 0 0;
    pointer-events: none;
  }

  &:after {
    margin: ${ringThickness.small + ringSpacing.small}rem
      ${ringThickness.small + ringSpacing.small}rem 0;
  }

  ${mq.medium} {
    position: static;
    margin-top: 0;
    padding: 0;

    &:before,
    &:after {
      border-width: ${ringThickness.medium}rem ${ringThickness.medium}rem 0;
    }

    &:after {
      margin: ${ringThickness.medium + ringSpacing.medium}rem
        ${ringThickness.medium + ringSpacing.medium}rem 0;
    }
  }
`

export const Title = styled.h2`
  ${heading.l};
  color: ${theme.color.text.light};

  ${mq.medium} {
    padding: 0 3rem;
  }
`

export const StyledCenter = styled(Center as any)`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mq.medium} {
    padding: 18rem 0 8.5rem;
  }
`
