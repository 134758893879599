import styled from 'styled-components'
import theme from 'styles/theme'
import mq from 'styles/mq'
import { heading } from 'styles/typography'

export const TabList = styled.div`
  display: flex;

  ${mq.desktop} {
    display: none;
  }
`

export const TabListItem = styled.button`
  flex: 1;
  border: 0;
  color: ${theme.color.border.blue};
  ${heading.m};
  font-size: 2rem;
  padding: 2.1rem 0;
  margin: 0;
  background: ${theme.color.background.gray};

  &[aria-selected='true'] {
    background: ${theme.color.accent.secondary};
    color: ${theme.color.text.light};
  }

  ${mq.desktop} {
    padding: 3.2rem 0;
  }
`

export const TabPanels = styled.div`
  ${mq.medium} {
    display: flex;
  }
`

export const ListItem = styled.li`
  padding: 3.2rem 3.4rem;
  background: ${theme.color.background.light};
  border-bottom: 1px solid ${theme.color.border.blue};
  text-align: center;

  &:last-child {
    border-bottom: 0;
  }

  ${mq.desktop} {
    border-right: 1px solid ${theme.color.border.blue};
    padding: 4.8rem 8.7rem;
  }
`

export const ListItemHeader = styled(ListItem as any)`
  display: none;

  ${mq.medium} {
    background: ${theme.color.accent.secondary};
    color: ${theme.color.text.light};
    border-right: 0;
  }
`

export const Table = styled.table<{ visibleColumn: number }>`
  width: 100%;
  border-collapse: collapse;

  td {
    display: none;
  }

  td:nth-child(${props => props.visibleColumn + 1}) {
    display: table-cell;
  }

  ${mq.desktop} {
    td {
      display: table-cell;
    }
  }
`

export const Td = styled.td`
  text-align: center;
  background: ${theme.color.background.light};
  padding: 3.2rem 3.4rem;

  ${mq.desktop} {
    padding: 4.8rem 8.7rem;
    width: 50%;
  }

  border-bottom: 1px solid ${theme.color.border.blue};

  &:first-child {
    border-right: 1px solid ${theme.color.border.blue};
  }
`

export const Th = styled.th`
  display: none;

  ${mq.desktop} {
    display: table-cell;
    color: ${theme.color.text.light};
    background: ${theme.color.accent.secondary};
    ${heading.m};
    padding: 3.2rem 0;
  }
`
