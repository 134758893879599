import styled, { css } from 'styled-components'

import theme from 'styles/theme'
import mq from 'styles/mq'
import { font, heading, paragraph } from 'styles/typography'

export const RadioInput = styled.input.attrs({ type: 'radio' })`
  display: none;
`

const activeBulletStyle = css`
  &:before {
    content: '';
    display: block;
    margin: auto;
    border-radius: 50%;
    width: 0.6rem;
    height: 0.6rem;
    background-color: ${theme.color.accent.secondary};
  }
`

export const Bullet = styled.span`
  display: flex;
  width: 1.4rem;
  height: 1.4rem;
  position: absolute;
  top: 0;
  right: 0;
  margin: 2.6rem 2.4rem;
  border: 2px solid currentColor;
  border-radius: 50%;
  color: ${theme.color.accent.secondary};

  ${mq.large} {
    position: static;
    top: auto;
    right: auto;
    margin: 0.5rem 2rem 0 0;
  }

  /* stylelint-disable-next-line */
  ${RadioInput}:checked + & {
    ${activeBulletStyle}
  }
`

export const Title = styled.span<{ textRight?: boolean }>`
  text-transform: uppercase;
  color: ${theme.color.accent.secondary};
  font-family: ${font.headings};
  ${paragraph.extraSmall};
  font-weight: bold;

  ${mq.large} {
    padding-left: 3.8rem;
    display: block;
  }
`

export const Container = styled.div`
  position: relative;
  margin: 0 1rem;
  flex-shrink: 0;
  width: 24rem;
  scroll-snap-align: center;
  background-color: ${theme.color.background.light};

  ${mq.medium} {
    scroll-snap-align: start end;
  }

  ${mq.large} {
    width: auto;
    padding: 0;
    background: none;

    &:nth-child(2n) {
      text-align: right;

      ${Bullet} {
        margin-right: 0;
        margin-left: 2rem;
        order: 2;
      }

      ${Title} {
        padding-left: 0;
        padding-right: 3.8rem;
      }
    }
  }
`

export const Description = styled.p`
  ${heading.s};
  color: #2e2e2e;
  flex: 1;
  margin-bottom: 0;

  ${mq.large} {
    color: ${theme.color.text.light};
    margin: 0;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: row;

  ${mq.large} {
    padding-top: 2.4rem;
  }
`

export const Label = styled.label`
  display: block;
  position: relative;
  padding: 2.4rem;
  cursor: pointer;

  ${mq.large} {
    position: static;
    padding: 0;
  }

  &:hover ${Bullet} {
    ${activeBulletStyle}
  }
`
