import React, { FC, SVGProps } from 'react'
import Svg from 'components/svg'

const ShootingStar: FC<SVGProps<SVGSVGElement>> = props => (
  <Svg {...props} viewbox="0 0 204 204">
    <path
      d="M150 65l16-65-50 42L62 6l24 63-50 42 47-3v5c-2 7-2 17-6 23l-4 4c-2 1-4 1-6-2-3-3-5-8-7-12l-2-2c-1-2-3-1-5-1-4 3-6 7-7 11l-5 18-2 17-2 8c-1 2-2 6-4 7-3 2-7-4-8-6-2-3-4-8-8-10-8-6-19 29-17 32 3 3 10-17 13-21 5-7 12 25 20 25 3 0 6-4 8-7l5-13 7-25c2-3 4-5 7-5s5 3 7 6c2 4 3 9 6 12 2 3 5 5 8 3 6-4 9-12 12-19 6-11 8-23 12-35l18 49 17-65 64-4-54-36z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </Svg>
)

export default ShootingStar
