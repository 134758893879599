import styled from 'styled-components'
import theme from 'styles/theme'
import mq from 'styles/mq'
import { Center } from '../styled'
import { heading } from 'styles/typography'
import Cigarette from 'components/icons/cigarette'
import MarryJane from 'components/icons/marry-jane'
import Junk from 'components/icons/junk'
import Sheep from 'components/icons/sheep'

export const InfoboxTitle = styled.h3`
  color: ${theme.color.accent.secondary};
  text-align: center;
  margin: 2.4rem 0;

  ${mq.medium} {
    ${heading.m};
    font-size: 9.2rem;
    line-height: 1.2;
    margin: 4rem 0;
  }
`

export const InfoboxText = styled.p`
  text-align: center;
  color: ${theme.color.text.light};
  margin: 0;

  ${mq.medium} {
    font-size: 2.4rem;
  }
`

export const InfoboxIcon = styled.span`
  color: ${theme.color.text.blue};
  text-align: center;
  display: block;
`

export const InfoboxListItem = styled.li`
  margin-bottom: 6.4rem;

  ${mq.medium} {
    margin-bottom: 10rem;
  }

  &:last-child {
    margin-bottom: 0;
  }
`

export const StyledCenter = styled(Center as any)`
  max-width: 30.3rem;
  padding: 0;

  ${mq.medium} {
    max-width: 64rem;
  }
`

export const CigaretteIcon = styled(Cigarette)`
  width: 2.6rem;
  height: 6.8rem;

  ${mq.medium} {
    width: 4rem;
    height: 12.8rem;
  }
`

export const MarryJaneIcon = styled(MarryJane)`
  width: 12.8rem;
  height: 8.8rem;

  ${mq.medium} {
    width: 21.2rem;
    height: 14.6rem;
  }
`

export const JunkIcon = styled(Junk)`
  width: 8.8rem;
  height: 8.8rem;

  ${mq.medium} {
    width: 16.1rem;
    height: 16.1rem;
  }
`

export const SheepIcon = styled(Sheep)`
  width: 10.4rem;
  height: 7.6rem;

  ${mq.medium} {
    width: 18.6rem;
    height: 13.6rem;
  }
`
