import React from 'react'
import { SectionTitle, Section, Center } from '../styled'
import Tabs from './Tabs'

const Mythbusters = () => (
  <Section>
    <SectionTitle>Mythbusters</SectionTitle>
    <Center>
      <Tabs />
    </Center>
  </Section>
)

export default Mythbusters
