import React, { FC } from 'react'
import Infobox from 'containers/sperm-101/Infobox'
import Cross from 'components/icons/cross'
import Tick from 'components/icons/tick'
import { IconContainer } from '../styled'

type Props = {
  text: string
  positive?: boolean
}

const Item: FC<Props> = ({ text, positive = false }) => (
  <Infobox
    text={text}
    icon={
      <IconContainer positive={positive}>
        {positive ? <Tick /> : <Cross />}
      </IconContainer>
    }
  />
)

export default Item
