import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import { withSmoothScroll } from 'common/polyfills'
import Layout from 'components/layout'
import SEO from 'components/seo'
import Categories from 'components/categories'
import urls from 'common/urls'

import YourHands from './your-hands'
import Mythbusters from './mythbusters'
import Sample from './sample'
import Future from './future'
import Footer from './footer'
import Header from './header'
import HardFacts from './hard-facts'
import { Wrap } from './styled'

type QueryData = {
  allCategoriesJson: {
    nodes: readonly BlogCategory[]
  }
}

const query = graphql`
  query {
    allCategoriesJson {
      nodes {
        ...categoryFields
      }
    }
  }
`

const Sperm101 = () => {
  const data = useStaticQuery<QueryData>(query)
  const categories = data.allCategoriesJson.nodes

  return (
    <Layout withHeaderBorder>
      <SEO title="Sperm 101" />
      <Categories categories={categories} current={urls.sperm101} />

      <Wrap>
        <Header />
        <HardFacts />
        <Sample />
        <YourHands />
        <Mythbusters />
        <Future />
        <Footer />
      </Wrap>
    </Layout>
  )
}

export default withSmoothScroll(Sperm101)
