import React, { FC, SVGProps } from 'react'
import Svg from 'components/svg'

const MarryJane: FC<SVGProps<SVGSVGElement>> = props => (
  <Svg {...props} viewbox="0 0 128 88">
    <g fill="currentColor" fillRule="nonzero">
      <path d="M91 26a17 17 0 100 34 17 17 0 000-34zm0 30a13 13 0 110-26 13 13 0 010 26z" />
      <path d="M92 18c-12 0-22 8-25 19h-6a26 26 0 00-49 0H6c-3 0-6 3-6 7a6 6 0 006 6h6a26 26 0 0049 0h6a26 26 0 0050 0h5c3 0 6-3 6-6 0-4-3-7-6-7h-6c-2-11-13-19-24-19zM6 46l-2-2 1-2 1-1h5a25 25 0 000 5H6zm30 19a21 21 0 110-43 21 21 0 010 43zm26-19a25 25 0 000-5h4a25 25 0 000 5h-4zm30 19a21 21 0 110-43 21 21 0 010 43zm30-24l1 1 1 2a2 2 0 01-2 2h-5a25 25 0 000-5h5z" />
      <path d="M37 26a17 17 0 100 34 17 17 0 000-34zm0 30a13 13 0 110-26 13 13 0 010 26z" />
      <path d="M30 37h4v4h-4zM94 37h4v4h-4zM118 66c0-1-1-2-3-2l-2 2c0 4-3 8-7 8l-2 2 2 2c4 0 7 4 7 8l2 2c2 0 3-1 3-2 0-4 3-8 7-8l2-2-2-2c-4 0-7-4-7-8zm-3 13l-2-3 2-3 3 3-3 3zM11 22l1 2h3l1-2c0-4 3-8 7-8l2-2-2-2c-4 0-7-4-7-8 0-1-1-2-3-2l-2 2c0 4-3 8-7 8l-2 2 2 2c4 0 7 4 7 8zm2-13l3 3-3 3-2-3 2-3z" />
    </g>
  </Svg>
)

export default MarryJane
