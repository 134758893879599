import React, { MouseEvent as ReactMouseEvent, useRef } from 'react'

import urls from 'common/urls'
import Container from 'components/container'
import {
  Content,
  Wrap,
  Title,
  IconContainer,
  StyledWhaleIcon,
  ButtonDown,
  Description,
} from './styled'

const Header = () => {
  const containerRef = useRef<HTMLDivElement>(null)
  const handleClick = (evt: ReactMouseEvent<HTMLAnchorElement, MouseEvent>) => {
    evt.preventDefault()
    ;(containerRef.current?.nextSibling as HTMLDivElement).scrollIntoView({
      behavior: 'smooth',
    })
  }

  return (
    <Container ref={containerRef}>
      <Wrap>
        <IconContainer>
          <StyledWhaleIcon />
        </IconContainer>

        <Content>
          <Title>Sperm 101</Title>
          <Description>
            Everything you wanted to know about your swimmers. (And a number of
            things you didn’t know you wanted to.)
          </Description>
          <ButtonDown onClick={handleClick} to={urls.sperm101} inverse>
            Learn more
          </ButtonDown>
        </Content>
      </Wrap>
    </Container>
  )
}

export default Header
