import React from 'react'

import { Section } from '../styled'
import {
  StyledCenter,
  ShootingStarIcon,
  SectionDescription,
  SectionTitle,
} from './styled'

const Future = () => (
  <Section>
    <SectionTitle noMarginBottom>Sperm’s the future.</SectionTitle>
    <SectionDescription>
      Semen is a window into men’s overall health; just as skin and teeth can
      reveal clues about your internal health, semen can as well. So the more we
      know about sperm as a health indicator, the more we can help collectively
      make strides in fertility, men’s health, and the health of our future
      generations. And that’s what<span translate="no"> Fellow </span>is all about.
    </SectionDescription>
    <StyledCenter>
      <ShootingStarIcon />
    </StyledCenter>
  </Section>
)

export default Future
