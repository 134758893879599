import styled, { css } from 'styled-components'

import { font, heading, paragraph } from 'styles/typography'
import theme from 'styles/theme'
import mq from 'styles/mq'

export const ringThickness = { small: 2, medium: 5, large: 7.2 }
export const ringSpacing = { small: 2, medium: 5.5, large: 8.2 }

export const Wrap = styled.article`
  background: ${theme.color.background.dark};
`

export const Section = styled.section`
  padding: 0 0 7.2rem 0;
  counter-increment: sections;

  ${mq.medium} {
    padding: 0 0 14rem 0;
  }
`

export const Center = styled.div`
  max-width: 118rem;
  margin: auto;
  padding: 0 3rem;

  ${mq.medium} {
    padding-left: 5rem;
    padding-right: 5rem;
  }
`

export const SectionTitle = styled.h2<{ noMarginBottom?: boolean }>`
  ${heading.xl};
  padding: 0 3rem;
  text-align: center;
  color: ${theme.color.accent.secondary};
  text-transform: uppercase;
  letter-spacing: 0.1rem;

  ${({ noMarginBottom }) =>
    !noMarginBottom &&
    css`
      margin-bottom: 6.4rem;

      ${mq.medium} {
        margin-bottom: 10rem;
      }
    `}

  &:before {
    ${heading.xl};
    content: counter(sections, decimal-leading-zero);
    display: block;
    margin: 0 -3rem 2rem !important;
    padding-bottom: 1rem;
    text-align: center;
    border-bottom: 3px solid currentColor;
    line-height: 1;

    ${mq.medium} {
      margin-bottom: 5rem !important;
      padding-bottom: 0;
      font-size: 14.5rem;
    }
  }
`

export const SectionCounter = styled.span`
  ${heading.xl};
  text-align: center;
  color: ${theme.color.accent.secondary};
  display: block;
  margin-bottom: 0;
  font-family: ${font.base};

  ${mq.medium} {
    font-size: 14.5rem;
  }
`

export const SectionTitleLine = styled.span`
  display: block;
  width: 100%;
  background: ${theme.color.accent.secondary};
  height: 0.3rem;
  margin: 2.45rem 0;

  ${mq.medium} {
    margin: 4rem 0;
  }
`

export const SectionDescription = styled.p`
  ${paragraph.small};
  color: ${theme.color.text.light};
  text-align: center;
  margin: auto;
`
